<template>
  <section>
    <TopBanner title="Career" :src="require('@/assets/images/banners/banner_career.jpg')" position="80% 0%" />
    <v-container class="content-container">
      <div id="careers">
        <div class="text-center">
          <p class="primary--text text-h5 text-sm-h3 font-weight-bold">
            See how far your thinking can go.
          </p>
          <div class="primary--text text-body-1 text-sm-h6 font-weight-light">
            Coming from a wide and surprising range of backgrounds, 
          </div>
          <div class="primary--text text-body-1 text-sm-h6 font-weight-light">
            our teams work together to provide the best solutions and services to our customers.
          </div>          
        </div>
          <section v-if="status && status === 200 && postings">
            <v-card
              class="ma-4 py-1"
              tile
            >
                <v-card
                  v-for="(jp, index) in postings"
                  :key="`career-id-${jp.jobPostingId}`"
                  class="mx-4 my-6"
                  flat
                >
                  <v-row no-gutters>
                    <v-col cols="12">
                      <div class="text-h6">
                        <router-link
                          :to="`career-detail?postingId=${jp.jobPostingId}`"
                        >
                          {{ jp.subject }}
                        </router-link>
                      </div>
                    </v-col>
                    <v-col cols="8">
                      <div class="font-weight-light">
                        Posting Date: {{ jp.startDate | moment('MM/DD/YYYY') }} | {{ jp.city }}, {{ jp.state }}
                      </div>
                    </v-col>
                    <v-col cols="2">
                      <div class="font-weight-light">
                        {{ jp.organization }}
                      </div>
                    </v-col>
                    <v-col cols="2">
                      <div class="font-weight-light">
                        {{ jp.jobType }}
                      </div>
                    </v-col>
                    <v-col cols="8">
                      <v-chip
                        v-for="tag in jp.tags.trim().split(',')"
                        :key="`career-tag-${jp.jobPostingId}-${tag}`"
                        class="mr-1"
                        color="lightgrey"
                        label
                      >
                        {{ tag }}
                      </v-chip>
                    </v-col>
                  </v-row>
                  <v-divider v-if="index < postings.length - 1" class="my-4"></v-divider>
                </v-card>
            </v-card>
          </section>
          <section v-else-if="status && status !== 200">
            <span class="error--text">Error</span>
          </section>
          <section v-else>
            <v-card
              v-for="index in 4"
              :key="`skeleton-${index}`"
              class="ma-4 pa-5"
              tile
            >
              <v-skeleton-loader
                class="mx-auto"
                max-width="100%"
                type="text@3"
              ></v-skeleton-loader>
            </v-card>
          </section>
      </div>
    </v-container>
    <Footer />
  </section>
</template>

<script>
import TopBanner from "@/components/TopBanner";
import Footer from "@/views/layouts/Footer";
import axios from "axios";

export default {
  components: {
    TopBanner,
    Footer
  },
  async created(){
    const resp = await this.getJobPostings();
    if(resp.status === 200){
      this.status = resp.status;
      this.postings = resp.data; 
    }
    else {
      this.errorMessage = resp.message;
    }
  },
  data(){
    return {
      postings: null,
      status: null,
      errorMessage: null
    };
  },
  methods: {
    async getJobPostings(){
      var res = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/api/Feature/Public/JobPostings`,
        {
          headers: {
            Authorization: `Basic ${btoa(this.$store.state.basicAuth.userName + ':' + this.$store.state.basicAuth.password)}`,
          },
        })
        .then(resp => {
          return resp;
        })
        .catch(err => {
          return err.response;
        });

      return res;
    }
  }
}
</script>